import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import { Bills } from "../pages/Bills";
import { Clients } from "../pages/Clients";
import { Contract } from "../pages/Contract";
import { Login } from "../pages/Login";
import { Request } from "../pages/Request";
import { Training } from "../pages/Training";
import { ContractDetails } from "../pages/ContractDetails";
import { AdminRequest } from "../pages/AdminRequest";
import { UserCreator } from "../pages/UserCreator";
import { Dashboard } from "../pages/Dashboard";
import { ResetPassword } from "../pages/ResetPassword";
import { NewPassword } from "../pages/NewPassword";
import { ChangePassword } from "../pages/ChangePassword";

function RoutesComponent() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
            <Routes>
                {/*<Route path="contratos/:id" element={<DetailContract />} />*/}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/" element={<Login />} />
                <Route path="clientes" element={<Clients />} />
                <Route path="contratos" element={<Contract />} />
                <Route path="autofacturas" element={<Bills />} />
                <Route path="peticiones" element={<Request />} />
                <Route path="formacion" element={<Training />} />
                <Route path="contratos/:contractId" element={< ContractDetails />} />
                <Route path="peticiones-admin" element={< AdminRequest />} />
                <Route path="creador-usuarios" element={< UserCreator />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="new-password" element={<NewPassword />} />
                <Route path="change-password" element={<ChangePassword />} />

            </Routes>
        </BrowserRouter>
    );
}

export default RoutesComponent;
