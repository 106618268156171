import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { esES } from '@mui/x-date-pickers/locales';

import { ThemeProvider, createTheme } from "@mui/material/styles";



const theme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#00716B',
        light: '#00d4c8',
        dark: '#005450',
      },
    },
    esES,
    typography: {
      h4: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      },
      body1: {
        fontFamily: '"Arial", sans-serif',
      },
      button: {
        textTransform: 'none'
      }
    },
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <App />
    </ThemeProvider>
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
