import React, { useEffect, useState } from 'react';
import { CircularProgress, Button, Box, TablePagination, TableFooter, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@mui/material';
import { useTheme, Typography, TextField } from '@mui/material';
import Swal from 'sweetalert2';

import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { findAllRequests, addResponse } from '../adapters/api'

import Header from '../components/Header';

import Popup from 'reactjs-popup';

function createData(data) {
    const { createdDate, active, contractId, state, issue, userType, _id, evidenceName, evidencePath } = data;
    const formattedCreatedDate = new Date(createdDate).toISOString().split("T")[0];

    return {
        createdDate: formattedCreatedDate,
        active,
        contractId,
        state,
        issue,
        userType,
        _id,
        evidenceName,
        evidencePath
    };
}

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            <Box sx={{ flexShrink: 0, ml: 2.5, display: "flex" }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export const AdminRequest = () => {
    const [rows, setRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [request, setRequest] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showPopup, setShowPopup] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState('');
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [dateSortOrder, setDateSortOrder] = useState("");
    const [isLoading, setIsLoading] = useState(true)

    const sortByState = () => {
        const newOrder = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(newOrder);

        const sortedRows = rows.sort((a, b) => {
            if (a.state < b.state) {
                return sortOrder === "asc" ? -1 : 1;
            }
            if (a.state > b.state) {
                return sortOrder === "asc" ? 1 : -1;
            }
            return 0;
        });

        setRows(sortedRows);
    };

    const sortByDate = () => {
        const newOrder = dateSortOrder === "asc" ? "desc" : "asc";
        setDateSortOrder(newOrder);

        const sortedRows = [...rows].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);

            if (dateA < dateB) {
                return dateSortOrder === "asc" ? -1 : 1;
            }
            if (dateA > dateB) {
                return dateSortOrder === "asc" ? 1 : -1;
            }
            return 0;
        });

        setRows(sortedRows);
    };

    const handleShowPopup = (rowId) => {
        setSelectedRowId(rowId);
        setShowPopup(true);
    };

    const handleHidePopup = () => {
        setShowPopup(false);
        setSelectedRowId(null);
    };

    async function populateRows() {
        const data = await findAllRequests();
        setRequest(data.data.issue);

        const newRows = [];
        for (let index = 0; index < data.data.issue.length; index++) {
            const rowData = createData(data.data.issue[index]);
            newRows.push(rowData);
        }
        setRows(newRows);
        setIsLoading(false);
    }

    useEffect(() => {
        populateRows();
    }, []);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSend = async () => {
        const response = await addResponse({ _id: selectedRowId, response: textFieldValue });
        console.log('Response from server:', response);

        setSelectedRowId(null);
        setTextFieldValue('');
        setShowPopup(false);

        if (response.status === 200) {
            Swal.fire(
                'Respuesta exitosa',
                'La respuesta se ha enviado correctamente',
                'success'
            ).then(() => {
                populateRows();
            })
        } else {
            Swal.fire(
                'Error en la respuesta',
                'Ha ocurrido un error al enviar la respuesta',
                'error'
            );
        }

    };

    const downloadFile = (path, name) => {
        const link = document.createElement('a');
        link.href = `https://comerciales.smartsenergies.com/api/request/download/${path}`;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Box className='container'>
            <Header title="Peticiones administrador" />

            <Box
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.1)',
                    padding: '40px',
                    margin: '5% 1%'
                }}>
                <TableContainer sx={{ pt: '1rem' }}>
                    <Typography variant="h4"
                        style={{
                            borderBottom: '1px solid #ddd',
                            margin: '10px 5px'
                        }}
                    >
                        Peticiones
                    </Typography>


                    {isLoading ? (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center',height:'50px' }}>
                                <CircularProgress />
                            </div>
                        </>
                    ) : (
                        <>
                            <Table sx={{ minWidth: 650, mt: 4 }} padding='checkbox' aria-label="simple table">
                                <TableHead >

                                    <TableRow style={{ backgroundColor: '#e0e0e0' }} >
                                        <TableCell style={{ cursor: 'pointer' }} onClick={sortByState}><b>Estado</b> <b style={{ float: 'right' }}>▲▼</b></TableCell>
                                        <TableCell align="center"><b>Petición</b></TableCell>
                                        <TableCell style={{ cursor: 'pointer' }} align="center" onClick={sortByDate}><b>Fecha</b><b style={{ float: 'right' }}>{dateSortOrder === "asc" ? "▼" : dateSortOrder === "desc" ? "▲" : "▼▲"}</b> </TableCell>
                                        <TableCell >Evidencia</TableCell>
                                        <TableCell ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>

                                </div>
                                <TableBody>

                                    {(rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row, index) => (
                                        <TableRow
                                            style={{ backgroundColor: index % 2 ? '#f7f7f7' : 'white' }}
                                            key={row.contractId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >{row.state === 0 ? 'Pendiente' : 'Respondida'}</TableCell>
                                            <TableCell sx={{ width: 350, padding: '15px' }}>{row.issue}</TableCell>
                                            <TableCell align="center">{row.createdDate}</TableCell>
                                            <TableCell style={{ cursor: 'pointer' }} onClick={() => downloadFile(row.evidencePath, row.evidenceName)} align="center">{row.evidenceName}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleShowPopup(row._id)}>Resolver</Button>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow >
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={4}
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>


                        </>
                    )}








                </TableContainer>
                <Popup open={showPopup} onClose={handleHidePopup}>
                    <Box className="popup-container" id="popup">
                        <Box className="popup">
                            <Box className="popup-header"><h2>Responde a la petición</h2></Box>

                            <Box className="popup-content" style={{ margin: '40px 30px 40px 25px' }}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Escribe aquí tu respuesta"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    value={textFieldValue}
                                    onChange={(e) => setTextFieldValue(e.target.value)}
                                />
                            </Box>

                            <Box className="popup-footer">
                                <Button style={{ margin: '5px' }} variant="contained" color="secondary" onClick={handleHidePopup}>Cancelar</Button>
                                <Button style={{ margin: '5px' }} variant="contained" color="primary" onClick={handleSend}>Enviar</Button>
                            </Box>
                        </Box>
                    </Box>
                </Popup>
            </Box>
        </Box>
    )
}