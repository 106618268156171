import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { refreshToken, findOneUser } from '../adapters/api';
import Swal from 'sweetalert2';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SchoolIcon from '@mui/icons-material/School';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BarChartIcon from '@mui/icons-material/BarChart';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import smartSP_logo from '../assets/images/logos/smartSP_logo.png'

export default function TemporaryDrawer(props) {

    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const refreshUserToken = async () => {
            try {
                const { data } = await refreshToken();
                localStorage.setItem('auth-token', data.token);
            }
            catch (error) {
                // todo: move to AuthService or something to be reused
                localStorage.clear();
                Swal.fire({
                    title: 'Sesión expirada',
                    text: 'Su sesión ha caducado. Haz click en el botón para ir al login',
                    icon: 'warning',
                    confirmButtonText: 'Login'
                }).then(() => {
                    navigate('/');
                })
            }
        };

        refreshUserToken();
    }, []);

    useEffect(() => {
        const checkIfAdmin = async () => {
            try {
                const user = await findOneUser();
                if (user.data.user.isAdmin) {
                    setIsAdmin(true);
                }
            } catch (error) {

            }
        };
        checkIfAdmin();
    }, []);


    const [state, setState] = React.useState({
        left: false
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const logout = async (event) => {
        event.preventDefault();
        localStorage.clear();
        navigate('/');
    }

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div style={{ backgroundColor: '#00716B', textAlign: 'center', overflow: 'hidden' }}>
                <img
                    src={smartSP_logo}
                    alt="Smart SP Logo"
                    style={{
                        width: '170px',
                        objectFit: 'contain',
                        transform: 'scale(1.2)',
                    }}
                />
            </div>
            <Divider />
            <List>
                {[
                    { text: 'Dashboard', url: '/dashboard', icon: <BarChartIcon color='primary' /> },
                    { text: 'Nuevo Contrato', url: '/contratos', icon: <NoteAddIcon color='primary' /> },
                    { text: 'Mis Clientes', url: '/clientes', icon: <PeopleIcon color='primary' /> },
                    { text: 'Autofacturas', url: '/autofacturas', icon: <ReceiptLongIcon color='primary' /> },
                    { text: 'Formación', url: '/formacion', icon: <SchoolIcon color='primary' /> },
                    { text: 'Mis Peticiones', url: '/peticiones', icon: <QuestionAnswerIcon color='primary' /> },
                    ...(isAdmin ? [{ text: 'Responder Peticiones', url: '/peticiones-admin', icon: <QuestionAnswerIcon color='primary' /> }] : []),
                    ...(isAdmin ? [{ text: 'Crear usuario', url: '/creador-usuarios', icon: <PersonAddIcon color='primary' /> }] : [])
                ].map((item, index) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon color='primary' >
                                {item.icon}
                            </ListItemIcon>
                            <Link style={{ textDecoration: 'none' }} to={item.url}><ListItemText primary={item.text} /></Link>
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem disablePadding onClick={logout}>
                    <ListItemButton>
                        <ListItemIcon color='primary' >
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Cerrar sesión'} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>



            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Grid container>
                        <Grid item xs={1} sm={1} md={1}>
                            <Button onClick={toggleDrawer(anchor, true)}><MenuIcon /></Button>
                        </Grid>
                        <Grid sx={{ textAlign: 'center', marginTop: '-40px' }} item xs={12} sm={12} md={12}>
                            <Typography variant='h4' fontWeight={'500'}

                            >{props.title}</Typography>
                        </Grid>
                    </Grid>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}