import StpApi from "../utils/stp-api";

const stpApi = new StpApi(process.env.REACT_APP_SMARTSTP_API_URL);

//Users
export const login = (email, password) => {
    return stpApi.postUnsigned(`/auth/login`, { email, password })
}

export const createUser = (user) => {
    return stpApi.post(`/users`, { user });
}

export const updateUser = (user) => {
    return stpApi.put(`/users/${user.id}`, { user });
}

export const findOneUser = () => {
    return stpApi.get(`/users`);
}

export const findOneUserById = (userId) => {
    return stpApi.get(`/users/getById/${userId}`);
}

export const resetPassword = (email) => {
    return stpApi.postUnsigned(`/auth/resetPassword`, { email });
}

export const setPassword = (password, passwordConfirm, token) => {
    return stpApi.postUnsigned(`/auth/newPassword`, { password, passwordConfirm, token });
}
export const changePassword = (password, newPassword, newPasswordConfirm) => {
    return stpApi.post(`/auth/changePassword`, { password, newPassword, newPasswordConfirm });
}

export const refreshToken = () => {
    return stpApi.post(`/auth/refreshToken`);
}

// Clients
export const createClient = (client) => {
    return stpApi.postUnsigned(`/clients`, { client });
}

export const updateClient = (client) => {
    return stpApi.put(`/clients/${client.id}`, { client });
}

export const findOneClient = (clientId) => {
    return stpApi.get(`/clients/${clientId}`);
}

export const findAllClients = (clientId) => {
    return stpApi.get(`/clients/allClients`);
}

// Contracts 
export const createContract = (contract, client) => {
    return stpApi.post(`/contracts`, { contract, client });
}

export const findOneContract = (contractId) => {
    return stpApi.get(`/contracts/${contractId}`);
}

export const findContractByClient = (contractId) => {
    return stpApi.get(`/contracts/findClientContract/${contractId}`);
}

export const findAllContracts = () => {
    return stpApi.get(`/contracts/allContracts`);
}

export const updateContract = (contract) => {
    return stpApi.put(`/contracts/${contract.contractId}`,{contract});
}

// Request
export const findAllRequests = () => {
    return stpApi.get(`/request/allRequest`);
}

export const createRequest = (issue, file) => {
    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('data', JSON.stringify({ issue }))
    return stpApi.post(`/request`, formData);
}

export const addResponse = (issue) => {
    return stpApi.put(`/request/addResponse`, { issue });
}

// Bills
export const findAllBills = () => {
    return stpApi.get(`/bills`);
}

// Products
export const findOneProduct = (productId) => {
    return stpApi.get(`/products/${productId}`);
}


// Rate
export const findOneRate = (rateId) => {
    return stpApi.get(`/rate/${rateId}`);
}


// Documents
export const createDocument = (file) => {
    const formData = new FormData();
    formData.append('file', file[0]);
    return stpApi.post('/documents', formData);
}

export const findAllDocuments = () => {
    return stpApi.get(`/documents`)
}
