import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Grid, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';
import Header from '../components/Header';
import Swal from 'sweetalert2';

import { useNavigate } from 'react-router-dom';
import { findOneUser, createUser } from '../adapters/api';



export const UserCreator = () => {

    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate()
    const [data, setData] = useState({});

    const handleChange = (e) => {
        setData({ ...data, [e.target.id || e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Agrega isAdmin a los datos que se enviarán
        const submissionData = { ...data, isAdmin };
        const response = await createUser(submissionData);
        if (response.status === 200) {
            Swal.fire(
                'Usuario creado',
                'El usuario se ha creado correctamente',
                'success'
            );
            // Navegar a otro lugar o resetear el formulario aquí si es necesario
        } else {
            Swal.fire(
                'Error',
                'Ha ocurrido un error al crear el usuario',
                'error'
            );
        }
    }

    // useEffect(() => {
    //     const checkIfAdmin = async () => {
    //         try {
    //             const user = await findOneUser();
    //             if (user.data.user.isAdmin === false) {
    //                 setIsAdmin(true);
    //                 Swal.fire({
    //                     title: 'Acceso denegado',
    //                     text: 'No tienes permisos para acceder a esta página. Haz clic en el botón para volver a la página principal.',
    //                     icon: 'warning',
    //                     confirmButtonText: 'Volver'
    //                 }).then(() => {
    //                     navigate('/dashboard');
    //                 })
    //             }
    //         } catch (error) {

    //         }
    //     };
    //     checkIfAdmin();
    // }, []);


    return (
        <Box className='container' style={{ margin: '0px 20px' }}>
            <Header title="Creación de usuario" />

            <form onSubmit={handleSubmit}>
                <Box
                    sx={{

                        "& .MuiOutlinedInput-root": {
                            "& > fieldset": { borderColor: "#005450" },
                        },
                    }}
                    className='contract-content'>
                    <Typography variant='h5' sx={{ textAlign: "left" }} className='contract-section-title'>
                        Datos
                    </Typography>
                    <Grid container columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                required
                                id='firstName'
                                label='Nombre'
                                value={data['firstName']}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                required
                                id='lastName'
                                label='Apellidos'
                                value={data['lastName']}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>



                        <Grid item xs={6} sm={4}>
                            <TextField
                                required
                                id='email'
                                label='Email'
                                value={data['email']}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                required
                                id='password'
                                label='Contraseña'
                                type='password'
                                value={data['password']}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <div >
                                <FormLabel id="demo-radio-buttons-group-label">Tipo de usuario</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="comercial"
                                    name="userType"
                                    sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-evenly' }}
                                    onChange={handleChange}
                                    id="userType"
                                >
                                    <FormControlLabel value="employee" control={<Radio />} label="Empleado" />
                                    <FormControlLabel value="contractor" control={<Radio />} label="Autónomo" />
                                </RadioGroup>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <div >
                                <FormLabel id="demo-radio-buttons-group-label">Tipo de jornada</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="fullTime"
                                    name="partTime"
                                    sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-evenly' }}
                                    onChange={handleChange}
                                    id="partTime"
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Media" />
                                    <FormControlLabel value={false} control={<Radio />} label="Completa" />
                                </RadioGroup>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isAdmin}
                                        onChange={(e) => setIsAdmin(e.target.checked)}
                                        name="isAdmin"
                                    />
                                }
                                label="Es administrador"
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ textAlign: 'center', paddingTop: '80px' }}>
                    <Button size="large" variant="contained" type="submit">Registrar nuevo usuario</Button>
                </Box>
            </form>
        </Box>
    );
}