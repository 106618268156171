import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { CircularProgress } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ToggleButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Swal from 'sweetalert2';
import Dropzone from 'react-dropzone';

import { findAllRequests, createRequest, createDocument } from '../adapters/api'

import Header from '../components/Header';
import useMediaQuery from '@mui/material/useMediaQuery';



function createData(data) {
    const { createdDate, active, contractId, state, issue, userType, response, evidenceName, evidencePath } = data;
    const formattedCreatedDate = new Date(createdDate).toISOString().split("T")[0];

    return {
        createdDate: formattedCreatedDate,
        active,
        contractId,
        state,
        issue,
        userType,
        response,
        evidenceName,
        evidencePath
    };
}

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            <Box sx={{ flexShrink: 0, ml: 2.5, display: "flex" }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const toggleButtonStyles = {
    width: '140px',
    '&.Mui-selected': {
        backgroundColor: '#00716B',
        color: 'white',
        '&:hover': {
            backgroundColor: '#005450'
        }
    }
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid grey',
    boxShadow: 24,
    p: 4,
};

export const Request = () => {

    const [alignment, setAlignment] = React.useState('ayuda_directa');
    const [requestText, setRequestText] = React.useState('');  // Nuevo estado para la petición
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const handleRequestChange = (event) => {  // Manejador de cambios para la petición
        setRequestText(event.target.value);
    };

    const [rows, setRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [request, setRequest] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!files.length) {
            Swal.fire(
                'Error en la petición',
                'Debe adjuntar una evidencia',
                'error'
            );
        }
        const response = await createRequest(requestText, files);
        if (response.status === 200) {
            setFiles([]);
            Swal.fire(
                'Petición exitosa',
                'La petición se ha completado correctamente',
                'success'
            ).then(() => {
                populateRows();
            })
        } else {
            Swal.fire(
                'Error en la petición',
                'Ha ocurrido un error al procesar la petición',
                'error'
            );
        }
    }


    async function populateRows() {
        const data = await findAllRequests();
        setRequest(data.data.issue);

        const newRows = [];
        for (let index = 0; index < data.data.issue.length; index++) {
            const rowData = createData(data.data.issue[index]);
            newRows.push(rowData);
        }
        setRows(newRows);
        setIsLoading(false);
    }

    useEffect(() => {
        populateRows();
    }, []);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const downloadFile = (path, name) => {
        const link = document.createElement('a');
        link.href = `https://comerciales.smartsenergies.com/api/request/download/${path}`;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleCreateDocument = async (files) => {
        setFiles(files);
        handleClose();
        Swal.fire(
            'Petición exitosa',
            'El documento se ha subido correctamente',
            'success'
        );
    };


    const matches = useMediaQuery("(min-width:850px)");

    return (
        <Box className='container'>
            <Header title="Peticiones" />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Dropzone onDrop={acceptedFiles => handleCreateDocument(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Haz click o arrastra documentos aquí para subirlos</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Box>
            </Modal>
            <div style={{ margin: '5%' }}>
                <Grid>
                    <Typography variant="h5" component="div" gutterBottom>
                        Nueva petición
                    </Typography>
                    <ToggleButtonGroup
                        sx={{
                            height: 40,
                            display: !matches ? 'flex' : '',
                            flexDirection: !matches ? 'column' : '',
                            alignItems: !matches ? 'center' : '',
                            justifyContent: !matches ? 'center' : '',
                            margin: matches ? 0 : '70px 0px 50px 0px',
                            mx: matches ? '0' : '300',
                        }}
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton
                            style={{ borderColor: 'lightgrey', borderWidth: '0.1px', borderStyle: 'solid' }}
                            sx={{ ...toggleButtonStyles }}
                            value="ayuda_directa"
                        >
                            Ayuda directa
                        </ToggleButton>

                        <ToggleButton
                            style={{ borderColor: 'lightgrey', borderWidth: '0.1px', borderStyle: 'solid' }}
                            sx={{ ...toggleButtonStyles }}
                            value="comunicacion"
                        >
                            Comunicación
                        </ToggleButton>

                        <ToggleButton
                            style={{ borderColor: 'lightgrey', borderWidth: '0.1px', borderStyle: 'solid' }}
                            sx={{ ...toggleButtonStyles }}
                            value="gestiones"
                        >
                            Gestiones
                        </ToggleButton>

                    </ToggleButtonGroup>
                </Grid>
                <Grid style={{ paddingTop: '2rem' }} container spacing={4}>
                    <Grid item xs={12} lg={8}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Petición"
                            multiline
                            rows={3}
                            className='request-textfield'
                            fullWidth
                            value={requestText}
                            onChange={handleRequestChange}

                        />
                    </Grid>
                    <Grid sx={{ marginTop: { xs: -2, lg: 8 } }} item xs={12} sm={6} lg={2}>
                        <Button fullWidth variant="contained" onClick={handleSubmit} >Enviar</Button>
                    </Grid>
                </Grid>
                <Grid container xs={4}>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                    />
                    <label htmlFor="raised-button-file">
                        <Button onClick={() => setOpen(true)} variant="contained">
                            Adjuntar archivo
                        </Button>
                    </label>
                </Grid>

                <TableContainer style={{ paddingTop: '2rem' }}>
                    <Typography variant="h5" component="div" gutterBottom>
                        Peticiones
                    </Typography>

                    {isLoading ? (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', height: '50px' }}>
                                <CircularProgress />
                            </div>
                        </>
                    ) : (

                        <Table sx={{ minWidth: 650, bgcolor: '#fafafa' }} padding='checkbox' aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ bgcolor: '#f0f0f0' }}>
                                    <TableCell sx={{ fontWeight: 'bold', py: 2, textAlign: 'center' }}>Estado</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', py: 2, textAlign: 'center' }}>Petición</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', py: 2, textAlign: 'center' }}>Fecha</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', py: 2, textAlign: 'center' }}>Evidencia</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', py: 2, textAlign: 'center' }}>Solución</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map((row, index) => (
                                    <TableRow
                                        key={row.contractId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, bgcolor: index % 2 ? '#f7f7f7' : 'white' }}
                                    >
                                        <TableCell sx={{ py: 2, textAlign: 'center' }}>{row.state === 0 ? 'Pendiente' : row.state === 1 ? 'Respondida' : ''}</TableCell>
                                        <TableCell sx={{ py: 2, textAlign: 'center' }}>{row.issue}</TableCell>
                                        <TableCell sx={{ py: 2, textAlign: 'center' }}>{row.createdDate}</TableCell>
                                        <TableCell onClick={() => downloadFile(row.evidencePath, row.evidenceName)} sx={{ py: 2, textAlign: 'center', cursor: 'pointer' }}>{row.evidenceName}</TableCell>
                                        <TableCell sx={{ py: 2, textAlign: 'center' }}>{row.response}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={4}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    )}
                </TableContainer>
            </div>
        </Box>
    )
}