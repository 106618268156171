import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom'

import { Box, Button, Grid, Typography, Badge, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { findAllRequests, findAllBills, findOneUser, findAllClients, findAllContracts } from '../adapters/api'

import Header from '../components/Header';

import {
    Chart as ChartJS,
    LinearScale,  // Add this import
    CategoryScale,
    BarElement,
    Legend,
    Tooltip,
    BarController,
} from 'chart.js';

import { Chart } from 'react-chartjs-2';

ChartJS.register(
    LinearScale,  // Add this registration
    CategoryScale,
    BarElement,
    Legend,
    Tooltip,
    BarController
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];



export const Dashboard = () => {
    const navigate = useNavigate()
    const [requestCount, setRequestCount] = useState(0);
    const [billLabels, setBillLabels] = useState([]);
    const [billData, setBillData] = useState([]);
    const [clientData, setClientData] = useState();
    const [userPoints, setUserPoints] = useState([]);
    const [totalBills, setTotalBills] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [monthlyStartedContracts, setMonthlyStartedContracts] = useState(0);
    const [monthlyEndedContracts, setMonthlyEndedContracts] = useState(0);

    async function countPendingRequests(isAdmin) {
        const data = await findAllRequests();
        let newRequestCount = 0;
        for (let index = 0; index < data.data.issue.length; index++) {
            if (isAdmin) {
                if (data.data.issue[index].state === 0) {
                    newRequestCount++;
                }
            } else {
                if (data.data.issue[index].state === 1) {
                    newRequestCount++;
                }
            }
        }
        setRequestCount(newRequestCount);
    }

    async function countContractDates() {
        try {
            const contracts = await findAllContracts();
            const currentMonth = new Date().getMonth();
            const currentYear = new Date().getFullYear();

            let started = 0;
            let ended = 0;

            for (let i = 0; i < contracts.data.contract.length; i++) {

                const createdDate = new Date(contracts.data.contract[i].createdDate);
                const endDate = new Date(contracts.data.contract[i].endDate);

                if (
                    createdDate.getMonth() === currentMonth &&
                    createdDate.getFullYear() === currentYear
                ) {
                    started++;
                }

                if (
                    contracts.data.contract[i].endDate &&
                    endDate.getMonth() === currentMonth &&
                    endDate.getFullYear() === currentYear
                ) {
                    ended++;
                }
            }

            setMonthlyStartedContracts(started);
            setMonthlyEndedContracts(ended);

        } catch (error) {

        }
    }

    async function getUserPoints() {
        try {
            const users = await findOneUser();
            if (users.data.user.isAdmin) {
                setIsAdmin(true);
            }
            setUserPoints(users.data.user.points);
            countPendingRequests(users.data.user.isAdmin);

        } catch (error) {

        }
    }

    async function countAllClients() {
        const clients = await findAllClients();
        setClientData(clients.data.clients.length);
    }

    async function getAllBills() {
        const bills = await findAllBills();
        setTotalBills(bills.data.bills.length);

        let currentDate = new Date();
        let currentMonth = currentDate.getUTCMonth() + 1;
        let currentYear = currentDate.getUTCFullYear();

        // Initialize last 12 months with zero amount
        let monthlyData = {};
        for (let i = 11; i >= 0; i--) {
            let month = (currentMonth - i - 1 + 12) % 12 + 1; // Calculate month (1-12)
            let year = currentYear + Math.floor((currentMonth - i - 1) / 12); // Calculate year
            let key = `${year}-${month < 10 ? '0' + month : month}`; // Normalize month to 2 digits
            monthlyData[key] = 0;
        }

        // Add bill amounts to the corresponding month
        for (let index = 0; index < bills.data.bills.length; index++) {
            let createdDate = new Date(bills.data.bills[index].createdDate);
            let month = createdDate.getUTCMonth() + 1;
            let year = createdDate.getUTCFullYear();

            let key = `${year}-${month < 10 ? '0' + month : month}`; // Normalize month to 2 digits

            if (monthlyData.hasOwnProperty(key)) {
                monthlyData[key] += bills.data.bills[index].amount; // Add the bill amount to the total
            }
        }

        // Convert to array and sort
        let sortedData = Object.entries(monthlyData)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([monthYear, total]) => ({ monthYear, total }));

        let labels = sortedData.map(item => item.monthYear);
        let data = sortedData.map(item => item.total);

        // Set the labels and data to the state
        setBillLabels(labels);
        setBillData(data);
    }




    useEffect(() => {
        getUserPoints();
        getAllBills();
        countAllClients();
        countContractDates();
    }, []);

    const data = {
        labels: billLabels,
        datasets: [
            {
                type: 'bar',
                label: 'Facturación (€)',
                backgroundColor: 'rgb(0, 255, 0)',
                data: billData,
                borderColor: 'white',
                borderWidth: 2,
            },
        ],
    };

    return (
        <Box className='container'>
            <Header title="Dashboard" />
            <div style={{ margin: '0% 5%' }}>
                <Typography variant='h5' sx={{ fontWeight: '500', textAlign: "left" }} className='contract-section-title'>
                    Acceso rápido
                </Typography>

                <Grid container columnSpacing={4} rowSpacing={2}>
                    <Grid item sm={6} xs={12} md={3}>
                        <Button size="large" variant="contained" fullWidth onClick={() => navigate('/contratos')}>Nuevo Contrato</Button>
                    </Grid>
                    <Grid item sm={6} xs={12} md={3}>
                        <Button size="large" variant="contained" fullWidth onClick={() => navigate('/clientes')}>Mis clientes</Button>
                    </Grid>
                    <Grid item sm={6} xs={12} md={3}>
                        <Button size="large" variant="contained" fullWidth onClick={() => navigate('/formacion')}>Formación</Button>
                    </Grid>
                    <Grid item sm={6} xs={12} md={3}>
                        {requestCount > 0 &&
                            <Badge badgeContent={requestCount} color="error" sx={{ width: '100%' }} >
                                <Button size="large" variant="contained" fullWidth onClick={() => navigate('/peticiones')}>Mis peticiones</Button>
                            </Badge>
                        }
                        {requestCount === 0 &&
                            <Button size="large" variant="contained" fullWidth onClick={() => navigate('/peticiones')}>Mis peticiones</Button>
                        }
                    </Grid>
                </Grid>
                <div >
                    <Grid sx={{ display: 'flex', alignItems: 'center' }}
                        style={{ marginTop: '100px' }} container columnSpacing={4} rowSpacing={2}>

                        <Grid item sm={6} style={{ width: '100%' }}>
                            <Chart type='bar' data={data} />
                        </Grid>

                        <Grid item sm={6} >
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>

                                        <TableRow>
                                            <TableCell component="th" scope="row"><b>Nº de Clientes:</b></TableCell>
                                            <TableCell >{clientData}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><b>Tarifas vendidas:</b></TableCell>
                                            <TableCell >{totalBills}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><b>Nº de Altas:</b></TableCell>
                                            <TableCell >{monthlyStartedContracts}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><b>Nº de Bajas:</b></TableCell>
                                            <TableCell >{monthlyEndedContracts}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Grid>
                </div>
            </div>
        </Box>
    )
}
