import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Grid, Select, FormControl, MenuItem, InputLabel } from '@mui/material';
import Header from '../components/Header';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { createContract } from '../adapters/api';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

export const Contract = () => {

    const handleInputChange = (e) => {
        setClient({ ...client, [e.target.id || e.target.name]: e.target.value });
    }

    const handleDataChange = (e) => {
        setData({ ...data, [e.target.id]: e.target.value });
    };

    const handleBirthdateChange = (name, date) => {
        if (date && dayjs.isDayjs(date)) {
            const formattedDate = date.format('YYYY/MM/DD');
            setClient({ ...client, [name]: formattedDate });
        } else {
            setClient({ ...client, [name]: null });
        }
    };

    const handleDateChange = (name, date) => {
        if (date && dayjs.isDayjs(date)) {
            const formattedDate = date.format('YYYY/MM/DD');
            setData({ ...data, [name]: formattedDate });
        } else {
            setData({ ...data, [name]: null });
        }
    };

    const handleAlignmentChange = (event, newValues, id) => {
        if (id === 'rateId') {
            setData({ ...data, [id]: newValues });
        } else {
            const value = event.target.value;
            let newValueArray = typeof value === 'string' ? value.split(',') : value;
            setData({ ...data, [id]: newValueArray });
        }
    };

    const [client, setClient] = useState({
        nif: '',
        name: '',
        phone: '',
        email: '',
        cups: '',
        birthdate: null,
    });

    const [data, setData] = useState({
        rateId: [],
        addition: [],
        numContract: '',
        createdDate: null,
    });

    const toggleButtonStyles = {
        width: '140px',
        '&.Mui-selected': {
            backgroundColor: '#00716B',
            color: 'white',
            '&:hover': {
                backgroundColor: '#005450'
            }
        }
    };

    const validateDocId = (value) => {
        const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
        const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
        let str = value.toString().toUpperCase();

        if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

        const niePrefixes = {
            X: 0,
            Y: 1,
            Z: 2
        };

        str = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2');

        const letter = str.slice(-1);
        const number = str.slice(0, -1);

        return validChars.charAt(number % 23) === letter;
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validateCUPS(cups) {
        var re = /^ES\d{16}[a-zA-Z]{2}$/;
        return re.test(cups);
    }

    const handleSubmit = async () => {
        for (let key in client) {
            if (!client[key]) {
                return;
            }
        }

        if (!validateDocId(client['nif']) || !validateEmail(client['email']) || !validateCUPS(client['cups'])) {
            Swal.fire(
                'Error en la creación',
                'El formato de los datos aportados no es correcto',
                'error'
            );
            return;
        }

        for (let key in data) {
            if (key !== 'addition' && !data[key]) {
                return;
            }
        }

        try {

            const formattedClient = {
                ...client,
                birthdate: dayjs(client.birthdate).format("YYYY-MM-DD"),
            };

            const formattedData = {
                ...data,
                createdDate: dayjs(data.createdDate).format("YYYY-MM-DD"),
            };

            const response = await createContract(formattedData, formattedClient);

            if (response.status === 200) {
                Swal.fire(
                    'Contrato creado',
                    'El contrato se ha creado correctamente',
                    'success'
                );
            } else {
                Swal.fire(
                    'Error en la creación',
                    'Ha ocurrido un error al crear el contrato',
                    'error'
                );
            }

            setData({});
        } catch (error) {
            Swal.fire(
                'Error en la creación',
                'Ha ocurrido un error al crear el contrato',
                'error'
            );
        }
    };


    const matches = useMediaQuery("(min-width:850px)");

    return (
        <Box className='container' >

            <Header title="Contrato" />
            <div
                className='container'
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.1)',
                    padding: '30px 10px 50px 10px',
                    margin: '3%'
                }}
            >
                <Typography variant='h5' className='contract-section-title'>Producto</Typography>

                <Box className='contract-content'
                    sx={{
                        margin: matches ? 0 : '30px 0px 70px 0px',
                        mx: matches ? '0' : '300',
                    }}
                >
                    <ToggleButtonGroup
                        sx={{
                            height: 40,
                            display: !matches ? 'flex' : '',
                            flexDirection: !matches ? 'column' : '',
                            alignItems: !matches ? 'center' : '',
                            justifyContent: !matches ? 'center' : '',
                        }}
                        color="primary"
                        multiple
                        value={data.rateId}
                        id="rateId"
                        onChange={(e, newValues) => {
                            // Asegúrate de que la manipulación de los valores aquí sea correcta para manejar múltiples selecciones
                            setData({ ...data, rateId: newValues });
                        }}
                        aria-label="Platform"
                        orientation={`${matches ? `horizontal` : `vertical`}`}
                    >
                        <ToggleButton
                            style={{ borderColor: 'lightgrey', borderWidth: '0.1px', borderStyle: 'solid' }}
                            sx={{
                                ...toggleButtonStyles
                            }}
                            value={"1"}>0-10 kW 20TD
                        </ToggleButton>

                        <ToggleButton
                            style={{ borderColor: 'lightgrey', borderWidth: '0.1px', borderStyle: 'solid' }}
                            sx={{
                                ...toggleButtonStyles,
                            }} value={"2"}>10-15 kW 21A
                        </ToggleButton>

                        <ToggleButton
                            style={{ borderColor: 'lightgrey', borderWidth: '0.1px', borderStyle: 'solid' }}
                            sx={{
                                ...toggleButtonStyles,
                            }}
                            value={"3"}>+15 kW 30TD
                        </ToggleButton>

                        <ToggleButton
                            style={{ borderColor: 'lightgrey', borderWidth: '0.1px', borderStyle: 'solid', borderTopLeftRadius: !matches ? '5px' : '0px', borderTopRightRadius: '5px' }}
                            sx={{
                                ...toggleButtonStyles,
                                width: '220px',
                            }}
                            value={"5"}
                        >
                            Gas + Pack mantenimiento
                        </ToggleButton>

                        {/* <ToggleButton
                            style={{ borderColor: 'lightgrey', borderWidth: '0.1px', borderStyle: 'solid', borderTopLeftRadius: !matches ? '5px' : '0px', borderTopRightRadius: '5px' }}
                            sx={{
                                ...toggleButtonStyles,
                                width: '320px',
                            }}
                            value={"4"}
                        >
                            0-10 kW 20TD + Gas + Pack mantenimiento
                        </ToggleButton> */}

                    </ToggleButtonGroup>
                </Box>
                <Box sx={{ textAlign: 'center', paddingTop: '25px' }}>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="addition">Adicionales</InputLabel>
                        <Select
                           id="addition"
                           multiple
                           value={data.addition}
                           label="Adicionales"
                           labelId="addition"
                           onChange={(e) => handleAlignmentChange(e, null, 'addition')}
                           renderValue={(selected) => selected.join(', ')}
                        >
                            <MenuItem value={"iberdrola"}>Pack Iberdrola</MenuItem>
                            <MenuItem value={"mantenimiento"}>Pack Mantenimiento</MenuItem>
                            <MenuItem value={"urgencias"}>Urgencias eléctricas</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Typography variant='h5' className='contract-section-title'>Datos cliente</Typography>
                <Box className='contract-content'>
                    <Grid container rowSpacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid item xs={6} md={3}>
                            <TextField
                                required
                                id='nif'
                                label='NIF/NIE/CIF'
                                value={client['nif']}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                required
                                id='name'
                                label='Nombre completo'
                                value={client['name']}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                required
                                id='phone'
                                label='Tlf móvil'
                                value={client['phone']}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                required
                                id='email'
                                label='Email'
                                value={client['email']}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                required
                                id='cups'
                                label='CUPS'
                                value={client['CUPS']}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '56px',
                                                overflow: 'hidden',
                                                marginTop: '-6px'
                                            },

                                            '& .MuiFormLabel-root.Mui-focused': {
                                                color: 'primary.main',
                                            },
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'primary.main',
                                            },
                                            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'primary.main',
                                            },
                                            '& .MuiFormLabel-root.Mui-error': {
                                                color: 'primary.main',
                                            }
                                        }}

                                        fullWidth
                                        required
                                        id='birthdate'
                                        label='F. Nacimiento'
                                        format="DD-MM-YYYY"
                                        size="small"
                                        variant="outlined"
                                        value={client['birthdate'] ? dayjs(client['birthdate'], 'YYYY/MM/DD') : null}
                                        onChange={(date) => handleBirthdateChange('birthdate', date)}

                                    />

                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                required
                                id='address'
                                label='Dirección'
                                value={client['address']}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                required
                                id='numContract'
                                label='Nº Contrato'
                                value={data['numContract']}
                                onChange={handleDataChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '56px',
                                                overflow: 'hidden',
                                                marginTop: '-6px'
                                            },
                                            '& .MuiFormLabel-root.Mui-focused': {
                                                color: 'primary.main',
                                            },
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'primary.main',
                                            },
                                            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'primary.main',
                                            },
                                            '& .MuiFormLabel-root.Mui-error': {
                                                color: 'primary.main',
                                            }
                                        }}

                                        fullWidth
                                        id='createdDate'
                                        format="DD-MM-YYYY"
                                        size="small"
                                        label="Fecha de creación"
                                        variant="outlined"
                                        value={data['createdDate'] ? dayjs(data['createdDate'], 'YYYY/MM/DD') : null}
                                        onChange={(date) => handleDateChange('createdDate', date)}
                                    />

                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ textAlign: 'center', paddingTop: '80px' }}>
                    <Button variant='contained' size='large' onClick={handleSubmit} >Registrar nuevo contrato</Button>
                </Box>
            </div>
        </Box>
    );
}