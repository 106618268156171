import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import CardMedia from '@mui/material/CardMedia';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import Header from '../components/Header';

import image1 from '../assets/images/logos/smartSP_logo.png';
import doc1 from '../assets/documents/files/AF-1.0-SmartSTP.pdf';

import { createDocument, findAllDocuments } from '../adapters/api';
import Dropzone from 'react-dropzone';
import Swal from 'sweetalert2';



function createData(image, name, fileRoute) {
    return { image, name, fileRoute };
}

const cards = [
    createData(image1, 'Formacion 1', doc1),
];


export const Training = () => {

    const [open, setOpen] = useState(false);
    const [documents, setDocuments] = useState([]);
    const handleCreateDocument = async (files) => {
        const response = await createDocument(files);
        handleClose();
        if (response.status === 200) {
            Swal.fire(
                'Petición exitosa',
                'La petición se ha completado correctamente',
                'success'
            ).then(() => {
                loadDocuments();
            })
        } else {
            Swal.fire(
                'Error en la petición',
                'Ha ocurrido un error al procesar la petición',
                'error'
            );
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid grey',
        boxShadow: 24,
        p: 4,
    };

    const loadDocuments = async () => {
        const docs = [];
        const data = await findAllDocuments();
        for (let doc of data.data.docs) {
            docs.push(createData(image1, doc.filename, doc.path))
        }
        setDocuments(docs);
    }

    useEffect(() => {
        loadDocuments();
    }, [])

    useEffect(() => {
        loadDocuments();
    }, [open])

    const handleClose = () => setOpen(false);

    const downloadFile = (path, name) => {
        const link = document.createElement('a');
        link.href = `https://comerciales.smartsenergies.com/api/documents/download/${path}`;
        //link.href = `http://localhost:3000/${path}`;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Box className='container'>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Dropzone onDrop={acceptedFiles => handleCreateDocument(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Haz click o arrastra documentos aquí para subirlos</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Box>
            </Modal>
            <Header title="Formación" />
            <div
                className='container'
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.1)',
                    padding: '0px 20px 30px 20px',
                    margin: '5% 2%'
                }}
            >
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <h3 className='contract-section-title'>Documentos</h3>
                    </Grid>
                    <Grid item xs={3} alignItems={'right'}>
                        <Button sx={{ right: '20px' }} size="large" variant="contained" fullWidth onClick={() => setOpen(true)}>Nuevo Documento</Button>
                    </Grid>
                </Grid>
                <Grid container className='card_training_container'>

                    {documents.map((card) => (
                        <div className='card'
                            key={card.name}
                        >
                            <Card>
                                <CardMedia
                                    component="img"
                                    src={card.image}
                                    className='card_media'
                                    sx={{
                                        width: 250,
                                        height: 166,
                                        objectFit: "cover"

                                    }}
                                    alt={card.name}>

                                </CardMedia>
                                <Grid>
                                    <Grid container >
                                        <Grid item xs={10} className='download_text'>
                                            <span>{card.name}</span>
                                        </Grid>
                                        <Grid item xs={2} >
                                            <Button
                                                onClick={() => downloadFile(card.fileRoute, card.name)}
                                                fullWidth
                                                className='donwload_button'
                                                sx={{ float: 'right', minHeight: '40px', width: '45px', borderRadius: '0px' }}
                                                variant="contained" >
                                                <DownloadIcon />
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Card>

                        </div>
                    ))}
                </Grid>
            </div>
        </Box>
    )
}