import React, { useEffect, useState } from 'react';
import { Table, CircularProgress } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import pdfIcon from '../assets/images/icons/pdf_icon.png';
import xlsIcon from '../assets/images/icons/xls_icon.png';


import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import Header from '../components/Header';
import { findAllBills, findOneUserById, findAllContracts } from '../adapters/api';
import html2pdf from "html2pdf.js";
import 'jspdf-autotable';

function createData(data) {
    const { numBill, createdDate, amount, active, state, userId } = data;
    const formattedCreatedDate = new Date(createdDate).toISOString().split("T")[0];
    return { numBill, createdDate: formattedCreatedDate, amount, active, state, userId };
}

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            <Box sx={{ flexShrink: 0, ml: 2.5, display: "flex" }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export const Bills = () => {


    const [page, setPage] = React.useState(0);
    const [bills, setBills] = useState([]);
    const [data, setData] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    async function getPDFData(userId,createdDate) {
        const users = await findOneUserById(userId);
        const contracts = await findAllContracts();
        let totalAmount = 0;
        for (let index = 0; index < contracts.data.contract.length; index++) {
            contracts.data.contract[index].clientId = getRateAmount(contracts.data.contract[index].rateId);
            totalAmount = totalAmount + getRateAmount(contracts.data.contract[index].rateId);
            contracts.data.contract[index].rateId = getRateDescription(contracts.data.contract[index].rateId);
        }


        generatePDF(users, contracts.data.contract, totalAmount,createdDate);

    }

    function getRateDescription(id) {
        switch (id) {
            case "1": return "0-10 kW 20TD";
            case "2": return "10-15 kW 21A";
            case "3": return "+15 kW 30TD";
            case "4": return "0-10 kW 20TD + Gas + Pack mantenimiento";
            case "5": return "Gas + Pack mantenimiento"
            default: return "";
        }
    }

    function getRateAmount(id) {
        switch (id) {
            case "1": return 18;
            case "2": return 27;
            case "3": return 40;
            case "4": return 18;
            case "5": return 18;
            default: return "";
        }
    }



    const generatePDF = (users, contracts, amount,createdDate) => {

        const getMonth = (createdDate) => {
            const date = new Date(createdDate);
            const month = date.toLocaleString('es-ES', { month: 'long' });
            return month;
        }
    
        const month = getMonth(createdDate);

        let html = `
        <html>

        <head>
            <style>
                body {
                    font-family: Arial, sans-serif;
                }
        
                .title {
                    text-align: center;
                    margin-bottom: 20px;
                }
        
                h4 {
                    margin: 0;
                }
        
                .subheader {
                    background-color: lightgray;
                    color: #006400;
                    padding: 20px 0;
                    text-align: center;
                    font-size: 23px;
                    font-weight: bold;
                }
        
                .right {
                    float: right;
                    margin-right: 50px;
                    font-size: 14px;
                }
        
                .left {
                    float: left;
                    margin-left: 50px;
                    position: absolute;
                    top: 135px;
                    color: green;
                }
        
                .clear {
                    clear: both;
                }
        
                table {
                    border-collapse: collapse;
                    width: 90%;
                    margin: auto;
                    margin-top: 30px;
                }
        
                table.product-table {
                    border: 1px solid black;
                }
        
                table.product-table th,
                table.product-table td {
                    border: 1px solid black;
                    padding: 10px;
                }
        
                th {
                    background-color: #006400;
                    color: white;
                }
        
                .footer {
                    width: 100%;
                    text-align: center;
                    background-color: #f5f5f5;
                    margin: 40px 0 0 0;
                    padding: 10px 0;
                    font-size: 12px;
                    color: #777;
                }
            </style>
        </head>
        
        <body>
            <div class="title">
                <h1>Factura</h1>
                <div class="subheader"><i>Smart solutions +</i></div>
                <h2>Factura ventas ${month}</h2>
            </div>
            <div style="position: relative;">
                <div class="right">
                    <h4 style="color: #ea8509;">FACTURADO A (CLIENTE)</h4>
                    <p>Smart solutions plus s.l.u <br>07011 , PALMA DE MALLORCA <br>CIF : B67911164</p>
                    <h4 style="color: #ea8509;">PROVEEDOR</h4>
                    <p>Nombre: ${users.data.user.firstName} ${users.data.user.lastName}
                    <br>Email: ${users.data.user.email}
                </div>
                <div class="left clear">
                    <h4>Fecha de factura: ${createdDate}</h4>
                </div>
            </div>
            <div class="clear">
                <table class="product-table">
                    <tr>
                        <th>Número de contrato</th>
                        <th>Comisión</th>
                        <th>Producto</th>
                        <th>Adicionales</th>
                    </tr>
        `;

        for (let contract of contracts) {
            html += `
                <tr>
                    <td>${contract.numContract}</td>
                    <td>${contract.clientId} €</td>
                    <td>${contract.rateId}</td>
                    <td>${contract.addition}</td>
                </tr>
            `;
        }

        html += `
        </table>
        <table>
            <tr>
                <td style="text-align: right;">Base imponible</td>
                <td></td>
                <td>${amount}€</td>
            </tr>
            <tr>
                <td style="text-align: right;">IVA (+21%)</td>
                <td></td>
                <td>${amount*0.21}€</td>
            </tr>
            <tr>
                <td style="text-align: right;">IRPF (-15%)</td>
                <td></td>
                <td>${amount*0.15}€</td>
            </tr>
            <tr>
                <td style="text-align: right;">Total de la factura</td>
                <td></td>
                <td style="background-color: lightgray;">${amount + (amount*0.21) - (amount*0.15)}€</td>
            </tr>
        </table>
    </div>
    <div class="footer">
        <p>Documento emitido por SMART SOLUTIONS PLUS SLU, domicilio fiscal c/Aragón 91.07005 Palma de Mallorca, 07011 CIF: B67911164</p>
    </div>
</body>

</html>
        `;

        // Crear una nueva instancia de pdf
        const pdf = html2pdf().from(html).set({
            filename: 'bill.pdf',
            html2canvas: { scale: 2 },
            jsPDF: { orientation: 'portrait' }
        });

        // Guardar el PDF
        pdf.save();
    }






    async function populateRows() {
        const data = await findAllBills();
        setBills(data.data.bills);

        const newRows = [];
        for (let index = 0; index < data.data.bills.length; index++) {
            const rowData = createData(data.data.bills[index]);
            newRows.push(rowData);
        }
        setRows(newRows);
        setIsLoading(false);
    }

    useEffect(() => {
        populateRows();
    }, []);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return (
        <Box className='container'>
            <Header title="Autofacturas" />
            <div style={{ margin: '5%', backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.1)' }}>
                <TableContainer >

                    {isLoading ? (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', height: '50px' }}>
                                <CircularProgress />
                            </div>
                        </>
                    ) : (

                        <Table padding='1' style={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead >
                                <TableRow style={{ backgroundColor: '#f7f7f7' }} >
                                    <TableCell style={{ fontWeight: 'bold' }}>Nº de factura</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>Fecha</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>Total</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>Estado</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>Descargar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map((row, index) => (
                                    <TableRow
                                        key={row.numBill}
                                        style={{ backgroundColor: index % 2 ? '#f7f7f7' : 'white' }}
                                    >
                                        <TableCell style={{ paddingLeft: '50px' }}>{row.numBill}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.createdDate}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.amount}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.state}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <img style={{ margin: '4px', cursor: 'pointer' }} onClick={() => getPDFData(row.userId,row.createdDate)} src={pdfIcon} alt="PDF download icon" />
                                            <img style={{ margin: '4px', cursor: 'pointer' }} src={xlsIcon} alt="XLS download icon" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={4}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    )}
                </TableContainer>
            </div>
        </Box>

    )
}