import * as React from 'react';
import { changePassword } from '../adapters/api';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import smartSP_logo from '../assets/images/logos/smartSP_logo.png';

const Swal = require('sweetalert2');

export const ChangePassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            await changePassword(data.get('password'), data.get('newPassword'), data.get('newPasswordConfirm'));
            Swal.fire({
                title: 'Éxito',
                text: 'Contraseña cambiada correctamente',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Error al intentar cambiar contraseña',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        navigate('/dashboard'); 
    };


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Typography component="h1" variant="h5" className="title">
                    <Link href="/" variant="body2">
                        <img
                            src={smartSP_logo}
                            alt="Smart SP Logo"
                            style={{
                                width: '400px',
                                objectFit: 'cover',
                                borderRadius: '10px',
                                marginBottom: '10px'
                            }}
                        />
                    </Link>
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña actual"
                        type="password"
                        id="password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="newPassword"
                        label="Nueva contraseña"
                        type="password"
                        id="newPassword"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="newPasswordConfirm"
                        label="Confirmar contraseña"
                        type="password"
                        id="newPasswordConfirm"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 3 }}
                    >
                        Guardar
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}


