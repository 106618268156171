import * as React from 'react';
import { resetPassword } from '../adapters/api';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import smartSP_logo from '../assets/images/logos/smartSP_logo.png';
const Swal = require('sweetalert2');

export const ResetPassword = () => {
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            await resetPassword(data.get('email'));
            Swal.fire({
                title: 'Éxito',
                text: 'Email enviado correctamente',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Error al intentar resetear contraseña',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    };


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Typography component="h1" variant="h5" className="title">
                    <Link href="/" variant="body2">
                        <img
                            src={smartSP_logo}
                            alt="Smart SP Logo"
                            style={{
                                width: '400px',
                                objectFit: 'cover',
                                borderRadius: '10px',
                                marginBottom: '10px'
                            }}
                        />
                    </Link>
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 3 }}
                        >
                            Enviar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}


