import React from 'react';

import { useEffect, useState } from 'react';

import { Dialog, Select, MenuItem, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import Header from '../components/Header';

import { updateContract, findOneUser } from '../adapters/api';



export const ContractDetails = () => {

    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setEndDate('');
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const handleDateChange = (name, date) => {
        if (date && dayjs.isDayjs(date)) {
            const formattedDate = date.format('YYYY/MM/DD');
            setEndDate( formattedDate );
        } else {
            setEndDate( null );
        }
    };

    const [cups, setCups] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [rateId, setRateId] = useState(null);
    const [state, setState] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [contractId,setContractId] = useState(null);

    const [endDateFinal, setEndDateFinal] = useState(null);
    const [stateFinal, setStateFinal] = useState(null);


    useEffect(() => {
        setCups(JSON.parse(localStorage.getItem('cups')));
        setName(JSON.parse(localStorage.getItem('name')));
        setEmail(JSON.parse(localStorage.getItem('email')));
        setRateId(JSON.parse(localStorage.getItem('rateId')));
        setState(JSON.parse(localStorage.getItem('state')));
        setStateFinal(JSON.parse(localStorage.getItem('state')));
        setCreatedDate(JSON.parse(localStorage.getItem('createdDate')));
        setContractId(JSON.parse(localStorage.getItem('contractId')));
        setEndDateFinal(JSON.parse(localStorage.getItem('endDate')));
    }, []);

    useEffect(() => {
        const checkIfAdmin = async () => {
            try {
                const user = await findOneUser();
                if (user.data.user.isAdmin) {
                    setIsAdmin(true);
                }
            } catch (error) {

            }
        };
        checkIfAdmin();
    }, []);

    



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isAdmin) {

            const contract = {
                contractId: contractId,
                state: state
              };
              
              if (endDate !== null && endDate !== "") {
                contract.endDate = endDate;
              }
            console.log(state);
            const response = await updateContract(contract);
            console.log(response);
            if(response.status === 200){
                
                if (endDate !== null && endDate !== "") {
                    localStorage.setItem('endDate', JSON.stringify(endDate));
                    setEndDateFinal(endDate);
                  }
                  localStorage.setItem('state', JSON.stringify(state));
                  setStateFinal(state);
                  Swal.fire(
                    'Editado correctamente',
                    'Los campos se han editado correctamente',
                    'success'
                  )
            }else{
                Swal.fire(
                    'Error',
                    'Ha ocurrido un error al intentar editar los datos',
                    'error'
                  )
            }
            handleClose();
        }else{
            console.log('Error: Este usuario no tiene los permisos necesarios para crear usuarios nuevos')
        }
    }

    return (
        <>
            <Box className='container'>
                <Header title="Detalle de contrato" />
                <Grid container spacing={3} className='contractDetails-content'>
                    <Grid item sm={12} md={5}>
                        <TableContainer component={Paper} elevation={3} sx={{ padding: '20px', borderRadius: '10px', margin: '5%', backgroundColor: '#fff' }} aria-label="simple table">
                            <Table >
                                <TableHead>
                                    <Typography style={{ fontWeight: '600', paddingBottom: '10px', textAlign: 'center' }} variant='h5'>Cliente</Typography>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ width: '50px' }}><b>Nombre</b></TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><b>CUPS</b></TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{cups}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><b>Correo</b></TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{email}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item sm={0} md={1}></Grid>
                    <Grid item sm={12} md={5}>
                        <TableContainer component={Paper} elevation={3} sx={{ padding: '20px', borderRadius: '10px', margin: '5%', backgroundColor: '#fff' }} aria-label="simple table">
                            <Table>
                                <TableHead style={{ position: 'relative' }}>
                                    <Typography style={{ fontWeight: '600' }} variant='h5'>Producto</Typography>
                                    {isAdmin ? <Button style={{ position: 'absolute', right: '0px', top: '-5px' }} variant='contained' onClick={handleClickOpen} size='large'>Editar</Button> : ''}
                                    
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ width: '50px' }}><b>Estado</b></TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{stateFinal ? "Inactivo" : "Activo"}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><b>Producto</b></TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                          {(
                                            Array.isArray(rateId) ? rateId : [rateId]
                                          )
                                            .map((rateId) => {
                                              switch (rateId) {
                                                case "1":
                                                  return "0-10 kW 20TD";
                                                case "2":
                                                  return "10-15 kW 21A";
                                                case "3":
                                                  return "+15 kW 30TD";
                                                case "4":
                                                  return "0-10 kW 20TD + Gas";
                                                default:
                                                  return "";
                                              }
                                            })
                                            .filter((label) => label)
                                            .join(', ')}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><b>F.Alta</b></TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{createdDate}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><b>F.Baja</b></TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{endDateFinal}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>

            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Editar campos</DialogTitle>
                    <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Select
                            labelId="demo-simple-select-label"
                            id="state"
                            value={state}
                            onChange={handleStateChange}
                        >
                            <MenuItem value={0}>Activo</MenuItem>
                            <MenuItem value={1}>Inactivo</MenuItem>
                        </Select>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: '56px',
                                            overflow: 'hidden',
                                            marginTop: '-6px'
                                        },
                                        '& .MuiFormLabel-root.Mui-focused': {
                                            color: 'primary.main',
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                        '& .MuiFormLabel-root.Mui-error': {
                                            color: 'primary.main',
                                        }
                                    }}

                                    fullWidth
                                    id='endDate'
                                    format="DD-MM-YYYY"
                                    size="small"
                                    label="Fecha de finalización"
                                    variant="outlined"
                                    value={endDate ? dayjs(endDate, 'YYYY/MM/DD').format('YYYY-MM-DD') : null}
                                    onChange={(endDate) => handleDateChange('endDate', endDate)}
                                />

                            </DemoContainer>
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary">
                            Aceptar
                        </Button>
                        
                    </DialogActions>
                    </form>
                </Dialog>
            </div>

        </>
    )
}