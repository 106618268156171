import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CircularProgress} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { findAllClients, findContractByClient, findOneUserById } from '../adapters/api'

import Header from '../components/Header';

import SearchIcon from '../assets/images/icons/search-outline.svg';

var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

function createData(data, contract, user) {
    const { _id, cups, name, birthdate, active, email, phone, address, nif, rateId, addition, commercial,endDate, createdDate, numContract } = data;
    const formattedCreatedDate = new Date(createdDate).toISOString().split("T")[0];
    const formattedEndDate = contract.endDate ? new Date(contract.endDate).toISOString().split("T")[0] : null;

    return {
        firstName: user.firstName,
        lastName: user.lastName,
        numContract: contract.numContract,
        state: contract.state,
        cups,
        name,
        birthdate,
        active,
        email,
        phone,
        address,
        nif,
        rateId: contract.rateId,
        addition: contract.addition,
        commercial,
        createdDate: formattedCreatedDate,
        endDate: formattedEndDate,
        contractId: contract._id
    };
    
}

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            <Box sx={{ flexShrink: 0, ml: 2.5, display: "flex" }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export const Clients = () => {

    const [nifFilter, setNifFilter] = useState('');
    const [cupsFilter, setCupsFilter] = useState('');
    const [comercialFilter, setComercialFilter] = useState('');

    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [rows, setRows] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true)

    async function populateRows() {
        const data = await findAllClients();

        const newRows = [];
        for (let index = 0; index < data.data.clients.length; index++) {
            try {
                const contract = await findContractByClient(data.data.clients[index]._id);
                let users, rowData;

                if (contract.data.contract) {
                    users = await findOneUserById(contract.data.contract.userId);
                    rowData = createData(data.data.clients[index], contract.data.contract, users.data.user);
                    console.log(contract.data.contract.numContract);
                } else {
                    console.log(`No se encontró contrato para el cliente con ID: ${data.data.clients[index]._id}`);
                    // Creamos un 'rowData' con la información del cliente y valores vacíos (o por defecto) para los datos del contrato y el usuario.
                    rowData = createData(data.data.clients[index], {}, {});
                }

                newRows.push(rowData);
            } catch (error) {
                console.error(`Error buscando contrato para el cliente con ID: ${data.data.clients[index]._id}. Error: ${error}`);
                // En caso de error, todavía creamos una fila con la información del cliente.
                const rowData = createData(data.data.clients[index], {}, {});
                newRows.push(rowData);
            }
        }

        setRows(newRows);
        setIsLoading(false);
    }

    useEffect(() => {
        populateRows();
    }, []);





    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filterRows = () => {
        let filteredRows = rows;

        if (nifFilter) {
            filteredRows = filteredRows.filter((row) => {
                if (!row.nif) {
                    return false;
                }
                return row.nif.toLowerCase().includes(nifFilter.toLowerCase());
            });
        }

        if (startDate || endDate) {
            const start = startDate && dayjs(startDate).startOf('day');
            const end = endDate && dayjs(endDate).endOf('day');
            filteredRows = filteredRows.filter((row) => {
                if (!row.createdDate) {
                    return false;
                }
                const createdDate = dayjs(row.createdDate);
                return (!start || createdDate.isSameOrAfter(start, 'day')) &&
                    (!end || createdDate.isSameOrBefore(end, 'day'));
            });
        }

        if (cupsFilter) {
            filteredRows = filteredRows.filter((row) => {
                if (!row.cups) {
                    return false;
                }
                return row.cups.toString().includes(cupsFilter)
            });
        }

        if (comercialFilter) {
            const lowerCaseFilter = comercialFilter.toLowerCase();
            filteredRows = filteredRows.filter((row) => {
                if (!row.firstName && !row.lastName) {
                    // If firstName or lastName is not defined, exclude this row
                    return false;
                }
                const fullName = `${row.firstName} ${row.lastName}`.toLowerCase();
                return row.firstName.toLowerCase().includes(lowerCaseFilter)
                    || row.lastName.toLowerCase().includes(lowerCaseFilter)
                    || fullName.includes(lowerCaseFilter);
            });
        }

        return filteredRows;
    };
    const filteredRows = filterRows();
    const displayRows = rowsPerPage > 0 ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRows;
    return (

        <Box className='container'>

            <Header title="Clientes" />

            <div
                className='container'
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.1)',
                    padding: '40px 10px',
                    margin: '5%'
                }}
            >

                <Typography
                    variant='h4'
                    style={{
                        borderBottom: '1px solid #ddd',
                        margin: '20px 5px'
                    }}>
                    Filtros
                </Typography>

                <Grid sx={{

                    "& .MuiOutlinedInput-root": {
                        "& > fieldset": { borderColor: "#005450" },
                    },
                }} container spacing={3}>

                    <Grid item xs={6} md={2} >
                        <TextField
                            fullWidth
                            color="primary"
                            size="small"
                            label="NIF/NIE/CIF"
                            variant="outlined"
                            value={nifFilter}
                            onChange={(event) => setNifFilter(event.target.value)}
                        />
                    </Grid>

                    <Grid item xs={6} md={2} >
                        <TextField
                            fullWidth
                            size="small"
                            label="Cups"
                            variant="outlined"
                            value={cupsFilter}
                            onChange={(event) => setCupsFilter(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} >
                        <TextField
                            fullWidth
                            size='small'
                            label="Comercial"
                            variant="outlined"
                            value={comercialFilter}
                            onChange={(event) => setComercialFilter(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ marginTop: '-8px' }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: '40px',
                                            overflow: 'hidden',
                                        },
                                        '& .MuiFormLabel-root.MuiInputLabel-shrink': {
                                            marginTop: '0px'
                                        },
                                        '& .MuiFormLabel-root': {
                                            color: 'primary.main',
                                            borderColor: 'primary.main',
                                            marginTop: '-8px'
                                        },
                                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                        '& .MuiFormLabel-root.Mui-focused': {
                                            color: 'primary.main',
                                            marginTop: '0px'
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                        // This will override the default error styles
                                        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                        '& .MuiFormLabel-root.Mui-error': {
                                            color: 'primary.main',
                                        }
                                    }}

                                    fullWidth
                                    format="DD-MM-YYYY"
                                    size="small"
                                    label="Desde"
                                    variant="outlined"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                />
                                <DatePicker
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: '40px',
                                            overflow: 'hidden',
                                        },
                                        '& .MuiFormLabel-root.MuiInputLabel-shrink': {
                                            marginTop: '0px'
                                        },
                                        '& .MuiFormLabel-root': {
                                            color: 'primary.main',
                                            borderColor: 'primary.main',
                                            marginTop: '-8px'
                                        },
                                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                        '& .MuiFormLabel-root.Mui-focused': {
                                            color: 'primary.main',
                                            marginTop: '0px'
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                        // This will override the default error styles
                                        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                        '& .MuiFormLabel-root.Mui-error': {
                                            color: 'primary.main',
                                        }
                                    }}

                                    fullWidth
                                    format="DD-MM-YYYY"
                                    size="small"
                                    label="Hasta"
                                    variant="outlined"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={1} >
                        <Button fullWidth style={{ height: 38 }} variant="contained" onClick={filterRows}>
                            Filtrar
                        </Button>
                    </Grid>
                </Grid>

                <TableContainer sx={{ marginTop: '1rem' }}>
                    <h3>Datos Cliente</h3>

                    {isLoading ? (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center',height:'50px' }}>
                                <CircularProgress />
                            </div>
                        </>
                    ) : (
                    <Table sx={{ minWidth: 650 }} padding='checkbox' aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#f7f7f7' }} >
                                <TableCell sx={{ minHeight: '2000px' }}><b>Nº contrato</b></TableCell>
                                <TableCell align="center"><b>CUPS</b></TableCell>
                                <TableCell align="center"><b>Cliente</b></TableCell>
                                <TableCell align="center"><b>Contacto</b></TableCell>
                                <TableCell align="center"><b>Estado</b></TableCell>
                                <TableCell align="center"><b>Producto</b></TableCell>
                                <TableCell align="center"><b>Adicionales</b></TableCell>
                                <TableCell align="center"><b>F.Alta</b></TableCell>
                                <TableCell align="center"><b>F.Baja</b></TableCell>
                                <TableCell align="center"><b>Comercial</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayRows.map((row, index) => (
                                <TableRow
                                    style={{ backgroundColor: index % 2 ? '#e0e0e0' : 'white' }}
                                    key={row.numContract}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        {row.numContract ? (
                                            <Link
                                                to={`/contratos/${row.numContract}`}
                                                onClick={() => {
                                                    localStorage.removeItem('cups');
                                                    localStorage.removeItem('name');
                                                    localStorage.removeItem('email');
                                                    localStorage.removeItem('rateId');
                                                    localStorage.removeItem('state');
                                                    localStorage.removeItem('createdDate');
                                                    localStorage.removeItem('contractId');
                                                    localStorage.removeItem('endDate');
                                                    localStorage.setItem('cups', JSON.stringify(row.cups));
                                                    localStorage.setItem('name', JSON.stringify(row.name));
                                                    localStorage.setItem('email', JSON.stringify(row.email));
                                                    localStorage.setItem('rateId', JSON.stringify(row.rateId));
                                                    localStorage.setItem('state', JSON.stringify(row.state));
                                                    localStorage.setItem('createdDate', JSON.stringify(row.createdDate));
                                                    localStorage.setItem('contractId', JSON.stringify(row.contractId));
                                                    if (row.endDate) {
                                                        localStorage.setItem('endDate', JSON.stringify(row.endDate));
                                                    }else{
                                                        localStorage.setItem('endDate', JSON.stringify(''));
                                                    }
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img style={{ cursor: 'pointer', width: '18px' }} src={SearchIcon} alt="details icon" />
                                                    <span style={{ marginTop: '1px' }}><b>{row.numContract}</b></span>
                                                </div>
                                            </Link>
                                        ) : (
                                            <div>
                                                <span style={{ marginTop: '1px' }}><b>{row.numContract}</b></span>
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">{row.cups}</TableCell>
                                    <TableCell align="center">{row.name} - {row.nif}</TableCell>
                                    <TableCell align="center">{row.phone} {row.email}</TableCell>
                                    <TableCell align="center">{!row.state ? "Activo" : "Inactivo"}</TableCell>
                                    <TableCell align="center">
                                      {(
                                        Array.isArray(row.rateId) ? row.rateId : [row.rateId]
                                      )
                                        .map((rateId) => {
                                          switch (rateId) {
                                            case "1":
                                              return "0-10 kW 20TD";
                                            case "2":
                                              return "10-15 kW 21A";
                                            case "3":
                                              return "+15 kW 30TD";
                                            case "4":
                                              return "0-10 kW 20TD + Gas";
                                            case "5":
                                              return "Gas y mantenimiento";
                                            default:
                                              return "";
                                          }
                                        })
                                        .filter((label) => label)
                                        .join(", ")}
                                    </TableCell>
                                    <TableCell align="center">
                                      {(
                                        Array.isArray(row.addition) ? row.addition : [row.addition]
                                      )
                                        .map((addition) => {
                                          switch (addition) {
                                            case "urgencias":
                                              return "Urgencias Eléctricas";
                                            case "iberdrola":
                                              return "Iberdrola";
                                            case "mantenimiento":
                                              return "Mantenimiento";
                                            default:
                                              return "";
                                          }
                                        })
                                        .filter((label) => label)
                                        .join(", ")}
                                    </TableCell>
                                    <TableCell align="center">{row.createdDate}</TableCell>
                                    <TableCell align="center">{row.endDate}</TableCell>
                                    <TableCell align="center">{row.firstName} {row.lastName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow >

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={9}
                                    count={filteredRows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />

                            </TableRow>
                        </TableFooter>
                    </Table>
                    )}
                </TableContainer>
            </div>

        </Box>
    )
}