import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Grid, Paper, Typography, Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { login } from '../adapters/api';
import smartSP_logo from '../assets/images/logos/smartSP_logo.png'
import Swal from 'sweetalert2';

export const Login = () => {

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            const res = await login(data.get('email'), data.get('password'));
            if (res) {
                if (res.data.token) {
                    localStorage.setItem('auth-token', res.data.token);
                    if(res.data.firstTime){
                       navigate('/change-password');
                    }else{
                       navigate('/dashboard'); 
                    }
                    
                }
            }else{
                Swal.fire(
                    'Error',
                    'Ha ocurrido un error al iniciar sesión',
                    'error'
                  )
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 8,
            }}
        >

            <Paper
                elevation={3}
                sx={{
                    padding: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 1,
                    bgcolor: 'background.paper',
                    overflow: 'hidden',
                    borderRadius: '10px',
                    boxShadow: 1,
                    maxWidth: 400,
                    width: '100%',
                    boxShadow: '0px 10px 50px -5px rgba(0, 0, 0, 0.75)',
                }}
            >
                <img
                    src={smartSP_logo}
                    alt="Smart SP Logo"
                    style={{
                        width: '400px',
                        objectFit: 'cover',
                        borderRadius: '10px',
                        marginBottom: '10px'
                    }}
                />

                <Box
                    noValidate
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        className="red-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Entrar
                    </Button>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item sx={{ textAlign: 'center' }} xs={6}>
                            <MuiLink component={RouterLink} to="/reset-password">
                                ¿Olvidaste tu contraseña?
                            </MuiLink>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Box>

    )
}